import PropTypes from 'prop-types';
import Link from 'next/link';
import { addTrailingSlash } from '../../../utils/utility';
import CustomLink from '../../Links/CustomLink';
import CorusImage from '../../CorusImage/CorusImage';

const FeaturedImage = ({ settings }) => {
	const {
		desc,
		link,
		imageUrl,
		tag: { slug, name },
		title,
	} = settings;
	return (
		<div className="featured-image-container">
			<div
				className="featured-image"
				aria-label="Featured Image"
			>
				<CustomLink href={link} className="featured-image-link">
					<CorusImage
						alt={title}
						title={title}
						height={853}
						src={imageUrl}
						width={1280}
						priority
					/>
				</CustomLink>
			</div>
			<div className="featured-image-metabox" aria-label="Featured Image Metabox Section">
				<div className="featured-image-tag" aria-label="Featured Image Tag">
					<Link href={`/tag/${addTrailingSlash(slug)}`}>{name}</Link>
				</div>
				<CustomLink href={link} className="featured-image-title">{title}</CustomLink>
				<p className="featured-image-desc">{desc}</p>
			</div>
		</div>
	);
};

export default FeaturedImage;

FeaturedImage.propTypes = {
	settings: PropTypes.shape({
		desc: PropTypes.string,
		link: PropTypes.string,
		imageUrl: PropTypes.string,
		tag: PropTypes.shape({
			slug: PropTypes.string,
			name: PropTypes.string,
		}),
		title: PropTypes.string,

	}).isRequired,
};
